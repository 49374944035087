import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import s from './CardCompany.module.scss'
import { Button, Form, FormGroup, Input, Label } from 'reactstrap'
import { getProfile } from '../../../../features/user/userActions'
import { selectProfile } from '../../../../selectors/selectUser'

function CardCompany() {

  const dispatch = useDispatch()

  const user_id = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE)).user.user_id
    
  const profile = useSelector(selectProfile, shallowEqual)

  const [data, setData] = useState(null)

  useEffect(() => {
    dispatch(getProfile({ user_id }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user_id])
    
    
  useEffect(() => { 
    console.log(profile)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [profile])

  return (
    <div className={s.root}>
      <div className={s.content}>
        <Form className={s.form}>
          <div className={s.identity}>
            <FormGroup className={s.formGroup}>
              <Label className={s.label}>Nom</Label>
              <Input className={s.input} type="text" value={profile?.company.company_name || ''} onChange={(e) => setData({ ...data, firstname: e.target.value })}/>
            </FormGroup>

            <FormGroup className={s.formGroup}>
              <Label className={s.label}>N° SIRET</Label>
              <Input className={s.input} type="text" value={profile?.company.registration_number || ''} onChange={(e) => setData({ ...data, lastname: e.target.value })}/>
            </FormGroup>
            
            <FormGroup className={s.formGroup}>
              <Label className={s.label}>Groupe</Label>
              <Input className={s.input} type="text" value={profile?.company.group || 'Informatique'} onChange={(e) => setData({ ...data, lastname: e.target.value })}/>
            </FormGroup>
          </div>
        </Form>
      </div>
    </div>
  )
}

export default CardCompany
