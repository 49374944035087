import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import s from './Devices.module.scss'
import { useMatch } from 'react-router-dom'
import HeaderDevices from '../../components/Headers/HeaderDevices/HeaderDevices'
import Equipments from './components/Equipments/Equipments'
import { getBuildings } from '../../features/buildings/buildingsActions'
import { getDevices } from '../../features/devices/devicesActions'
import Monitoring from './components/Monitoring/Monitoring'
import EquipmentsLinked from './components/EquipementLinked/EquipmentsLinked'
import EquipmentsUnlinked from './components/EquipmentsUnlinked/EquipmentsUnlinked'



function Devices() {

  const dispatch = useDispatch()

  const isMatchEquipements = useMatch('/devices/equipements')
  const isMatchEquipementsLinked = useMatch('/devices/equipmentLinked')
  const isMatchEquipmentsUnlicked = useMatch('/devices/equipmentUnlinked')
  
  const user_id = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE))?.user?.user_id;
  
  useEffect(() => { 
    if (user_id) {
      dispatch(getBuildings({ user_id }))
      dispatch(getDevices({ user_id })) 
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [user_id])

  return (
    <div className={s.root}>
      <HeaderDevices/>
      <div className={s.content}>
        {isMatchEquipements && <Equipments/>}
        {isMatchEquipmentsUnlicked && <EquipmentsUnlinked/>}
        {isMatchEquipementsLinked && <EquipmentsLinked />}
      </div>
    </div>
  )
}
export default Devices