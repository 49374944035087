import React from 'react';
import s from './TiersAccount.module.scss';
import CardProfile from '../CardProfile/CardProfile';
import CardEnedis from '../CardEnedis/CardEnedis';
import CardTiersAccount from '../CardTiersAccount/CardTiersAccount';

function TiersAccount() {
  return (
    <div className={s.root}>
      <div className={s.consent}>
        <div className={s.title}>
          Consentement Enedis
        </div>
        <div className={s.enedis}>
          <CardEnedis/>
          <span>Si vous souhaitez révoquer ce consentement avant sa fin, merci de nous contacter par mail sur contact@insensia.com.</span>
        </div>
      </div>
      <div className={s.line}></div>
      <div className={s.associated}>
        <div className={s.title}>
          Services connectés
        </div>
        <div className={s.tiersAccount}>
          <CardTiersAccount />
        </div>
      </div>
    </div>
  );
}

export default TiersAccount;
