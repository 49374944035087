import React from 'react';
import s from './ModalAssociateAccount.module.scss';
import Modal from '../../../../components/Modal/Modal'
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import Icon from '../../../../components/Icon/Icon';

function ModalAssociateAccount( {showModalAssociate, handleClose}) {

  return (
    <Modal show={showModalAssociate} >
      <div className={s.header}>
        <div className={s.title}>Associer un compte</div>
        <Icon name="close" size={'1.5rem'} color={'#30465E'} fill={0} onClick={handleClose} cursor/>
      </div>
      <div className={s.content}>
        <Form className={s.form}>
          <FormGroup className={s.formGroup}>
            <Label className={s.label}>Nom</Label>
            <Input className={s.input} type='text'/>
          </FormGroup>
          <FormGroup className={s.formGroup}>
            <Label className={s.label}>Prénom</Label>
            <Input className={s.input} type='text'/>
          </FormGroup>
          <FormGroup className={s.formGroup}>
            <Label className={s.label}>Email</Label>
            <Input className={s.input} type='text'/>
          </FormGroup>
        </Form>
        <div className={s.confirm}>
          <Button className={s.btn}>
            Changer de mot de passe
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default ModalAssociateAccount;