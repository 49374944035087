import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import s from './Equipments.module.scss'
import { getBuildings } from '../../../../features/buildings/buildingsActions';
import { getDevices } from '../../../../features/devices/devicesActions';
import { selectActiveBuilding, selectActiveRoom } from '../../../../selectors/selectNavigation';
import ModalDissociateDevice from '../ModalDissociateDevice/ModalDissociateDevice';
import CardDevice from '../CardDevice/CardDevice';
import { changeActiveBuildingDevice, changeActiveRoomDevice, initActiveDevice } from '../../../../features/navigation/navigationSlice';
import { saveNavigationState } from '../../../../features/navigation/navigationActions';
import store from '../../../../redux/store';
import ModalDeleteDevice from '../ModalDeleteDevice/ModalDeleteDevice';
import { selectListDevices } from '../../../../selectors/selectDevices';
import ModalUpdateDevice from '../ModalUpdateDevice/ModalUpdateDevice';

function Equipments() {
  
  const dispatch =  useDispatch()

  const user_id = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE))?.user?.user_id;
  
  const devices = useSelector(selectListDevices, shallowEqual)
  
  const [showModalDissociateDevice, setShowModalDissociateDevice] = useState(false)
  const [showModalUpdateDevice, setShowModalUpdateDevice] = useState(false)
  const [showModalDeleteDevice, setShowModalDeleteDevice] = useState(false)


  useEffect(() => { 
      if (user_id) {
        dispatch(getBuildings({ user_id }))
        dispatch(getDevices({ user_id })) 
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [user_id])

  const handleShowModal = (modalType) => {
    switch (modalType) {
      case 'settings':
        setShowModalUpdateDevice(true);
        break;
      case 'dissociate':
        setShowModalDissociateDevice(true);
        break;
      case 'delete':
        setShowModalDeleteDevice(true)
        break;
      default:
        break;
    }
  }

  const handleCloseModal = (modalType) => {
    switch (modalType) {
      case 'settings' :
        setShowModalUpdateDevice(false);
        dispatch(changeActiveBuildingDevice(null))
        dispatch(changeActiveRoomDevice(null))
        break;
      case 'dissociate':
        setShowModalDissociateDevice(false);
        break;
      case 'delete':
        setShowModalDeleteDevice(false)
        dispatch(changeActiveBuildingDevice(null))
        dispatch(changeActiveRoomDevice(null))
        break;
      default:
        break;
    }
    dispatch(initActiveDevice())
    dispatch(saveNavigationState({ user_id, state: { ...store.getState().navigation } }))
    .then(() => dispatch(getDevices({ user_id })))
    .then(() => dispatch(getBuildings({ user_id })))
  }


  return (
    <div className={s.root}>
      <div className={s.content}>
        
          <div className={s.title}>
            Liste de vos équipements
          </div>
          <div className={s.devices}>
            {devices?.length > 0 ? devices.map((device, index) => (
              <CardDevice key={index} device={device} handleShowModal={handleShowModal}/>
            )):
            <div className={s.noResult}> 
              Aucun résultats
            </div>}
          </div>
      </div>

      <ModalDeleteDevice showModalDeleteDevice={showModalDeleteDevice} handleClose={() => handleCloseModal('delete')}/>
      <ModalUpdateDevice showModalUpdateDevice={showModalUpdateDevice} handleClose={() => handleCloseModal('settings')}/>
      <ModalDissociateDevice showModalDissociateDevice={showModalDissociateDevice} handleClose={() => handleCloseModal('dissociate')}/>

    </div>
  )
}

export default Equipments
