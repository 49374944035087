import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import s from './CardAssociatedAccount.module.scss'
import { selectProfile } from '../../../../selectors/selectUser';
import { Button, ListGroup, ListGroupItem, Modal } from 'reactstrap';
import Icon from '../../../../components/Icon/Icon';
import Popup from '../../../../components/Popup/Popup';
import ModalAssociateAccount from '../ModalAssociateAccount/ModalAssociateAccount';

function CardAssociatedAccount({ handleShowModal }) {

  const dispatch = useDispatch()

  const user_id = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE)).user.user_id
  
  const profile = useSelector(selectProfile, shallowEqual)
  const [isDropdownOptionOpen, setIsDropdownOptionOpen] = useState(false)
  const [showModalDelete, setShowModalDelete ] = useState(false)
  const [showModalAssociate, setShowModalAssociate] = useState(false)
  const options = ['Passer administrateur', 'Supprimer le compte']

  const handleClose = () => {
    setShowModalAssociate(false)
    setShowModalDelete(false)
  }

  return (
    <div className={s.root}>
      <div className={s.content}>
        <ul className={s.cardInfo}>
          <li>
            <div className={s.infos}>
              <span>Kevin Lousky</span>
              <span>kevin@insensia.com</span>
            </div>
            <div className={s.other}>
              <div className={s.role}>
                admin
              </div>
              <div className={s.more}>
                <Icon name={'more_vert'} size={'1.2rem'} color={'#30465E'} onClick={() => setIsDropdownOptionOpen(true)} cursor />
              </div>
            </div>
          </li>
          <li>
            <div className={s.infos}>
              <span>Antoine Vidaling</span>
              <span>antoine@insensia.com</span> 
            </div>
            <div className={s.other}>
              <div className={s.role}>
                admin
              </div>
              <div className={s.more}>
                <Icon name={'more_vert'} size={'1.2rem'} color={'#30465E'} onClick={() => setIsDropdownOptionOpen(true)} cursor />
              </div>
            </div>
          </li>
          <li><span>...</span><span>...</span></li>
        </ul>
      </div>
      {isDropdownOptionOpen &&
        <ListGroup className={s.listGroup}>
          {options.map((option, index) => (
          <div className={s.item} key={index}>
            <ListGroupItem className={s.name} onClick={() => setIsDropdownOptionOpen(false)}>{option}</ListGroupItem> 
          </div>
          ))}
        </ListGroup>
        }

      <Popup show={showModalDelete} title={'Supprimer ce compte'} onAccept={() => setShowModalDelete(false)} onDiscard={() => setShowModalDelete(false)} />
      <ModalAssociateAccount showModalAssociate={showModalAssociate} handleClose={handleClose} />
    </div>
  )
}

export default CardAssociatedAccount
