import React, { useRef } from 'react';
import { Routes, Route, useMatch } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Logo from '../Logo/Logo';
import Sidebar from '../Sidebar/Sidebar';
import HeaderMobile from '../Headers/HeaderMobile/HeaderMobile';
import Buildings from '../../pages/Buildings/Buildings';
import BuildingSettings from '../../pages/BuildingSettings/BuildingSettings';
import Devices from '../../pages/Devices/Devices';
import Consult from '../../pages/Consult/Consult';
import Compare from '../../pages/Compare/Compare';
import Anomalies from '../../pages/Anomalies/Anomalies';
import Reports from '../../pages/Reports/Reports';
import Account from '../../pages/Account/Account';
import Error from '../../pages/Error/Error';
import s from './Layout.module.scss';
import Automation from '../../pages/Automation/Automation';
import ModalAddDevice from '../../pages/BuildingSettings/components/Devices/components/ModalAddDevice/ModalAddDevice'

function Layout() {
  
  const nodeRef = useRef(null);
  const matchDeviceAdd = useMatch('/devices/add/*')

  return (
    <div className={s.layout}>
      
      <div className={s.logo}>
        <Logo/>
      </div>

      <div className={s.headerMobile}>
        <HeaderMobile/>
      </div>

      <div className={s.sidebar}>
        <Sidebar/>
      </div>
     
      <main className={s.main}>
        <TransitionGroup>
          <CSSTransition nodeRef={nodeRef} classNames='fade' timeout={1000}>
            <Routes>

              {/* BUILDINGS */}
              <Route path='buildings' element={<Buildings/>}/>

              {/* BUILDING SETTINGS */}
              <Route path='settings/*' element={<BuildingSettings/>}/>

              {/* DEVICES */}
              <Route path='devices/*' element={<Devices/>}>
                <Route path='add/*' element={<ModalAddDevice showModalAddDevice={matchDeviceAdd}/>}/>
                {/* <Route path='add/:mac_address' element={<ModalAddDevice/>}/> */}
              </Route>
              

              {/* CONSULT */}
              <Route path='consult/*' element={<Consult/>}/>

              {/* AUTOMATION */}
              <Route path='automation/*' element={<Automation/>}/>

              {/* ANOMALIES */}
              <Route path='anomalies/*' element={<Anomalies />}/>

              {/* REPORTS */}
              <Route path='reports' element={<Reports/>}/>
     
              {/* ACCOUNT */}
              <Route path='account/*' element={<Account/>} />

              {/* OTHER */}
              <Route path='*' element={<Error />} />

            </Routes>
          </CSSTransition>
        </TransitionGroup>
      </main>
      <footer></footer>
    </div>
  );
}

export default Layout;
