import React, { useEffect, useRef, useState } from 'react';
import s from './Profile.module.scss';
import CardProfile from '../CardProfile/CardProfile';
import { selectProfile } from '../../../../selectors/selectUser';
import { shallowEqual, useSelector } from 'react-redux';
import Icon from '../../../../components/Icon/Icon';

function Profile() {

  const fileInputRef = useRef(null)
  const profile = useSelector(selectProfile, shallowEqual)
  const [imageURL, setImageURL] = useState('')
  const [isOn, setIsOn] = useState(true)

    useEffect(() => {
      if (!profile?.avatar_url) {
        setImageURL(`https://ui-avatars.com/api/?background=random&name=${profile?.firstname}+${profile?.lastname}`)
      } else {
        setImageURL(profile.avatar_url)
      }
    }, [profile])
    
    const handleAvatarClick = () => {
      if (fileInputRef.current) {
        fileInputRef.current.click()
      }
    }
  
    const handleFileChange = (e) => {
      const file = e.target.files[0]
      if (file) {
        const fileURL = URL.createObjectURL(file)
        setImageURL(fileURL)
      }
    }
  
    const handleDeleteImage = () => {
      setImageURL(`https://ui-avatars.com/api/?background=random&name=${profile.firstname}+${profile.lastname}`)
      fileInputRef.current.value = ''
    }
  return (
    <div className={s.root}>

      <div className={s.header}>
        <div className={s.avatar}>
          {!imageURL.includes('ui-avatars.com') && <div className={s.delete} onClick={handleDeleteImage}><Icon name="close" size={'1.5rem'} color={'#30465E'} fill={0} cursor/></div>}
          <img className={s.image} onClick={handleAvatarClick} src={imageURL} aria-hidden alt="Photo de mon profil" />
          <div className={s.overlay_circle} onClick={handleAvatarClick}><Icon name="photo_camera" size={'1.5rem'} color={'#30465E'} fill={0} cursor/></div>
        </div>
        <input type="file" accept="image/*" style={{display: 'none' }} ref={fileInputRef} onChange={handleFileChange}/>
      </div>

      <div className={s.profile}>
        <div className={s.title}>Mon profil - {profile?.role}</div>
        <CardProfile />
      </div>
      <div className={s.line}></div>
      <div className={s.preferences}>
        <div className={s.title}>
          Préférences
        </div>
        <div className={s.notification}>
        <span>Notifications : </span>
          <div className={s.toggleButton} onClick={() => setIsOn(!isOn)}>
            <div className={`${s.background} ${isOn ? s.background_on : s.background_off}`}>
              <div className={`${s.label} ${isOn ? s.on : s.off}`}></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
