import React, { useState } from 'react';
import s from './Company.module.scss';
import CardProfile from '../CardProfile/CardProfile';
import CardCompany from '../CardCompany/CardCompany';
import CardAssociatedAccount from '../CardAssociatedAccount/CardAssociatedAccount';
import { Button } from 'reactstrap';
import ModalAssociateAccount from '../ModalAssociateAccount/ModalAssociateAccount';

function Company() {

  const [showModalAssociate, setShowModalAssociate] = useState(false)

  const handleClose = () => {
    setShowModalAssociate(false)
  }

  return (
    <div className={s.root}>
      <div className={s.company}>
        <div className={s.title}>
          Entreprise
        </div>
        <div className={s.cardCompany}>
          <CardCompany/>
        </div>
      </div>
      <div className={s.line}></div>
      <div className={s.assocciate}>
        <div className={s.header}>
          <div className={s.title}>
            Comptes associés 
          </div>
          <div className={s.btn}>
            <Button className={s.btnAdd} onClick={() => setShowModalAssociate(true)}> Associer un compte </Button>
          </div>
        </div>
        <div className={s.associatedAccount}>
          <CardAssociatedAccount/>
        </div>
      </div>
      <ModalAssociateAccount showModalAssociate={showModalAssociate} handleClose={handleClose} />
    </div>
  );
}

export default Company;
