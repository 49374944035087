import React, { useEffect, useState } from 'react'
import s from './Sidebar.module.scss'
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { NavLink, useMatch, useNavigate } from 'react-router-dom'
import { changeActiveRoute } from '../../features/navigation/navigationSlice'
import { saveNavigationState } from '../../features/navigation/navigationActions'
import store from '../../redux/store';
import { selectProfile } from '../../selectors/selectUser'
import { getProfile } from '../../features/user/userActions'
import { getAlerts } from '../../features/alert/alertsActions'
import Icon from '../Icon/Icon'
import { resetState } from '../../features/auth/authActions'
import { userLogout } from '../../features/auth/authSlice'

function Sidebar() {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  
  const user_id = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE))?.user?.user_id;

  const isMatchBuildings = useMatch('/buildings/*')
  const isMatchSettings = useMatch('/settings/*')
  const isMatchDevices = useMatch('/devices/*')
  const isMatchAutomation = useMatch('/automation/*')
  const isMatchConsult = useMatch('/consult/*')
  //const isMatchCompare =  useMatch('/compare/*')
  const isMatchAnomalies = useMatch('/anomalies/*')
  const isMatchReports = useMatch('/reports/*')

  const isMatchAccount = useMatch('/account/*')

  const profile = useSelector(selectProfile, shallowEqual)

  const [imageURL, setImageURL] = useState('')

  const environment = process.env.NODE_ENV

  useEffect(() => {
    console.log(environment)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [environment])

  useEffect(() => {
      dispatch(getProfile({ user_id }))
      dispatch(getAlerts())
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user_id])

  useEffect(() => {
      if (!profile?.avatar_url) {
        setImageURL(`https://ui-avatars.com/api/?background=random&name=${profile?.firstname}+${profile?.lastname}`)
      } else {
        setImageURL(profile.avatar_url)
      }
      // eslint-disable-next-line
    }, [profile])

  const handleChangeActiveItem = (e, link, section) => {
    e.stopPropagation()
    dispatch(changeActiveRoute(link))
    if (section !== 'compare' && section !== 'security' && section !== 'account') {
      dispatch(saveNavigationState({ user_id, state: { ...store.getState().navigation } }))
    }
  }

  const disconnect = () => {
    dispatch(userLogout())
    dispatch((resetState()))
    navigate('/login')
  }

  return (
    <div className={s.root}>

      <div className={s.nav}>
        <div className={s.headerLink} onClick={(e) => handleChangeActiveItem(e, "/buildings", 'buildings')}>
          <NavLink to="/buildings" className={() => isMatchBuildings || isMatchSettings ? s.link_active : s.link}>
            <Icon name="domain" size={'1.5rem'} color={isMatchBuildings || isMatchSettings ? '#FFF' : '#3192D3'} fill={!isMatchBuildings && !isMatchSettings ? 1 : 0}/>
            <span className={s.item}>Bâtiments</span>
          </NavLink>
        </div>
      
        <div className={s.headerLink} onClick={(e) => handleChangeActiveItem(e, "/consult/summary", 'consult')}>
          <NavLink to="/consult" className={() => isMatchConsult ? s.link_active : s.link}>
            <Icon name="dashboard" size={'1.5rem'} color={isMatchConsult ? '#FFF' : '#3192D3'} fill={!isMatchConsult ? 1 : 0} opsz={48}/>
            <span className={s.item}>Consulter</span>
          </NavLink> 
        </div>

        <div className={s.headerLink} onClick={(e) => handleChangeActiveItem(e, "/devices", 'devices')}>
          <NavLink to="/devices" className={() => isMatchDevices ? s.link_active : s.link}>
            <Icon name="home_iot_device" size={'1.5rem'} color={isMatchDevices ? '#FFF' : '#3192D3'} fill={!isMatchDevices ? 1 : 0}/>
            <span className={s.item}>Équipements</span>
          </NavLink> 
        </div>

        {environment === 'development' &&
        <div className={s.headerLink}>
          <NavLink className={s.disable}>
            <Icon name="automation" size={'1.5rem'} color={'#595d78'}  fill={!isMatchAutomation ? 1 : 0}/>
            <span className={s.item}>Automatisation</span>
          </NavLink>
        </div>
        }
      
        {environment === 'production' && 
        <div className={s.headerLink} onClick={(e) => handleChangeActiveItem(e, "/automation/rules", 'automation')}>
          <NavLink className={() => isMatchAutomation ? s.link_active : s.link}>
            <Icon name="automation" size={'1.5rem'} color={isMatchAutomation ? '#FFF' : '#3192D3'}  fill={!isMatchAutomation ? 1 : 0}/>
            <span className={s.item}>Automatisation</span>
          </NavLink>
        </div>
        }
      
        <div className={s.headerLink} onClick={(e) => handleChangeActiveItem(e, "/anomalies", "anomalies")} >
          <NavLink to='/anomalies' className={() => isMatchAnomalies ? s.link_active : s.link}>
            <Icon name="warning" size={'1.5rem'} color={isMatchAnomalies ? '#FFF' : '#3192D3'} fill={!isMatchAnomalies ? 1 : 0}/>
            <span className={s.item}>Anomalies</span>
          </NavLink>
        </div>

        <div className={s.headerLink} onClick={(e) => handleChangeActiveItem(e, "/reports", 'reports')}>
          <NavLink to="/reports" className={() => isMatchReports ? s.link_active : s.link}>
            <Icon name="description" size={'1.5rem'} color={isMatchReports ? '#FFF' : '#3192D3'} fill={!isMatchReports ? 1 : 0}/>
            <span className={s.item}>Rapports</span>
          </NavLink>
        </div>

      </div>


      <div className={s.footerNav}>
      
        <div className={s.helper}>
          <NavLink to="#">
            <span className={s.link}>Mentions légales</span>
          </NavLink> 
        </div>
        
        <div className={s.profile}>
          <div className={s.account} to={`/account`} onClick={(e) => handleChangeActiveItem(e,'/account/my_account', 'account')}>
            <NavLink to="/account/my_account" className={() => isMatchAccount ? s.link_active : s.link}>
              <div className={s.avatar} id="tooltip-avatar">
                <img className={s.image} src={imageURL} aria-hidden alt="Photo de mon profil" />
              </div>
              <span className={s.item}>Mon profil</span>
            </NavLink>
          </div>

          <div className={s.disconnect}>
            <div className={s.btn}>
              <Icon  name={'logout'} size={'1.5rem'} color={'#E6514C'} onClick={disconnect} cursor/>
            </div>
          </div>
        </div>
      </div>
    </div> 
  )
}

export default Sidebar
