import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectActiveRoute } from '../selectors/selectNavigation';
import { doInit } from '../features/auth/authActions';

const useAuth = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const activeRoute = useSelector(selectActiveRoute);

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {

    const token = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE))?.token;
    
    const publicRoutes = ['/login', '/register', '/forgot', '/password-reset'];

    if (!token) {
      if (!publicRoutes.includes(location.pathname)) {
        console('Hello no match route')
        navigate('/login');
      }
      setLoading(false);
      return;
    }

    try {
      const tokenData = JSON.parse(atob(token.split('.')[1]));
      const tokenExpiration = tokenData.exp * 1000;
      const currentTime = new Date().getTime();

      if (tokenExpiration > currentTime) {
        setIsAuthenticated(true);
        dispatch(doInit()).then(() => {
          const lastPath = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE))?.navigation || activeRoute;
          navigate(lastPath);
        });
      }
    } catch (error) {
      localStorage.removeItem(process.env.REACT_APP_LOCAL_STORAGE);
      if (!publicRoutes.includes(location.pathname)) {
        navigate('/login');
      }
    } finally {
      setLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, dispatch, activeRoute]);

  return { isAuthenticated, loading };
};

export default useAuth;

