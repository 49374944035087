import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import s from './CardProfile.module.scss'
import { Form, FormGroup, Input, Button, Label } from "reactstrap"
import { selectProfile } from '../../../../selectors/selectUser';
import { getProfile } from '../../../../features/user/userActions';
import Popup from '../../../../components/Popup/Popup';

function CardProfile() {

  const dispatch = useDispatch()

  const user_id = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE)).user.user_id
  
  const profile = useSelector(selectProfile, shallowEqual)

  const [data, setData] = useState(null)
  const [isChangePasswordOpen, setIsChangePasswordOpen] = useState(false)
  const [isChangeMailOpen, setIsChangeMailOpen] = useState(false)

  useEffect(() => {
    dispatch(getProfile({ user_id }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user_id])
  
  
  useEffect(() => { 
    setData(profile)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [profile])

  const changeFirstname = (e) => {
    setData({...data, 
      name: e.target.value
    })
  }

  const add = () => {

  }

  const discard = () => {
    setData({
      firstname: profile?.firstname || '',
      lastname: profile?.lastname || '',
      phone: profile?.phone || '',
      email: profile?.email || '',
    });
  }

  const handleMail = () => {
    alert("popUp modif email  ")
  }

  const handlePassword = () => {
    alert("popUp modif mot de passe ")
  }

  return (
    <div className={s.root}>
      <div className={s.content}>
        <Form className={s.form}>
          <div className={s.identity}>
            <FormGroup className={s.formGroup}>
              <Label className={s.label}>Prénom</Label>
              <Input className={s.input} type="text" value={data?.firstname || ''} onChange={(e) => setData({ ...data, firstname: e.target.value })}/>
            </FormGroup>

            <FormGroup className={s.formGroup}>
              <Label className={s.label}>Nom</Label>
              <Input className={s.input} type="text" value={data?.lastname || ''} onChange={(e) => setData({ ...data, lastname: e.target.value })}/>
            </FormGroup>
          </div>

          <FormGroup className={s.formGroup}>
            <Label className={s.label}>Téléphone</Label>
            <Input className={s.input} type="text" value={data?.phone || ''} onChange={(e) => setData({ ...data, phone: e.target.value })}/>
          </FormGroup>
          
          <FormGroup className={s.formGroup}>
            <Label className={s.label}>Email</Label>
            <Input className={s.input} type="text" value={data?.email || ''} readOnly />
            
          </FormGroup>
        </Form>

        <div className={s.modify}>
          <Button className={s.linkUpdate} onClick={() => setIsChangeMailOpen(true)}>Changer d'email</Button>
          <Button className={s.linkUpdate} onClick={() => setIsChangePasswordOpen(true)}>Changer de mot de passe</Button>
        </div>
      </div>

      <Popup title={"Modifier mon email"} show={isChangeMailOpen} onDiscard={() => setIsChangeMailOpen(false)} onAccept={handleMail}>
        <Input className={s.input} placeholder={"exemple@mail.com"} type="text"/>
      </Popup>

      <Popup title={"Modifier mon mot de passe ? "} show={isChangePasswordOpen} onDiscard={() => setIsChangePasswordOpen(false)} onAccept={handlePassword}>
        <Input className={s.input} type="text"/>
      </Popup> 

    </div>
  )
}

export default CardProfile
