import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import s from './CardSubscription.module.scss'

function CardSubscription() {

  return (
    <div className={s.root}>
      <div className={s.content}>
        <ul className={s.cardInfo}>
          <li><span>Début de l'abonnement :</span><span>16/12/2024</span></li>
          <li><span>Fin de l'abonnement :</span><span>16/12/2025</span></li>
        </ul>
      </div>

    </div>
  )
}

export default CardSubscription
