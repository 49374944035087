import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import s from './NavigationDevices.module.scss'
import classnames from 'classnames/bind'
import { Button, Input } from "reactstrap"
import { useDispatch, useSelector } from 'react-redux'
import { changeActiveRoute, initActiveBuilding } from '../../../features/navigation/navigationSlice'
import { selectActiveRoute } from '../../../selectors/selectNavigation'
import { saveNavigationState } from '../../../features/navigation/navigationActions'
import store from '../../../redux/store';
import Icon from '../../Icon/Icon'

const cx = classnames.bind(s)

function NavigationDevices() {

  const dispatch = useDispatch()

  const user_id = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE))?.user?.user_id;
 
  const activeRoute = useSelector(selectActiveRoute);
  
  const [navigationIndex, setNavigationIndex] = useState(0)

  const itemNav = [
    {icon_name: 'home_iot_device', label: 'Tous les équipements'},
    {icon_name: 'domain', label: 'Associés à un bâtiment'},
    {icon_name: 'link_off', label: 'Non-associés'},
  ]
        
  const labelsEnglish = ['equipments', 'monitoring'];

  const [hoverIndex, setHoverIndex] = useState(null);

  const handleMouseEnter = (index) => setHoverIndex(index);
  const handleMouseLeave = () => setHoverIndex(null);

  useEffect(() => { 
    const extractedRoute = activeRoute.includes('/') ? activeRoute.split('/')[2] : '';
    const index = labelsEnglish.findIndex(label => label === extractedRoute)
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  },[])

  const isBtnDisabled = (index) => navigationIndex === index
  
  const handleClickNavigation = (index) => {
    setNavigationIndex(index)
    switch (index) {
      case 0:
        dispatch(changeActiveRoute('/devices/equipements'))
        break;
      case 1:
        dispatch(changeActiveRoute('/devices/equipmentLinked'))
        break;
      case 2:
        dispatch(changeActiveRoute('/devices/equipmentUnlinked'))
      default:
        break;
    }
    dispatch(saveNavigationState({ user_id, state: { ...store.getState().navigation } }));
  }

  return (
    <div className={s.nav}>
      <div className={s.nav_h}>
        <div className={s.menu}>
          {itemNav.map((item, index) => (
          <Button key={index} className={cx(s.btn, { [s.active]: navigationIndex === index, [s.disabled]: isBtnDisabled(index) })} 
          onClick={() => handleClickNavigation(index)}
          onMouseEnter={() => handleMouseEnter(index)}
          onMouseLeave={handleMouseLeave}
          disabled={isBtnDisabled(index)}>
            <Icon name={item.icon_name} size={'1.5rem'} color={hoverIndex === index || navigationIndex === index ? '#3192D3' : '#30465E'} fill={1}/>
            <span className={s.item}>{item.label}</span>
          </Button>
          ))}
        </div>
      </div>
      <div className={s.nav_v}>
        <Input className={s.input} name="select" type="select" value={navigationIndex} onChange={(e) => handleClickNavigation(parseInt(e.target.value))}>
          {itemNav.map((item, index) => (
          <option className={s.option} key={index} value={index}>{item.label}</option>
          ))}
        </Input>
      </div>
    </div>
  )
}

export default NavigationDevices
