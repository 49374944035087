import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux"
import s from './CardTiersAccount.module.scss'
import { Button } from "reactstrap"
import { getProfile } from '../../../../features/user/userActions';
import { useNavigate } from 'react-router-dom'
import { selectIsTiersUserConnected } from '../../../../selectors/selectTiersUser'
import { getTiersUserStatus, connectTiersUser, disconnectTiersUser } from '../../../../features/tiersUser/tiersUserAction'
import ModalLogoutTierAccount from '../ModalLogoutTierAccount/ModalLogoutTierAccount';

function CardTiersAccount() {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const IsNetatmoUserConnected = useSelector((state) => selectIsTiersUserConnected(state, "netatmo"));
  const IsSmartthingsUserConnected = useSelector((state) => selectIsTiersUserConnected(state, "smartthings"));
  const clientIdSmartthing = process.env.REACT_APP_SMARTTHINGS_CLIENT_ID;
  const clientIdNetatmo = process.env.REACT_APP_NETATMO_CLIENT_ID;
  const redirectUri = process.env.REACT_APP_REDIRECT_URI;
  const scopes = 'r:devices:*';
  const netatmoState = 'netatmo'
  const smartThingsAuthUrl = `https://api.smartthings.com/oauth/authorize?client_id=${clientIdSmartthing}&response_type=code&redirect_uri=${redirectUri}&scope=${scopes}`;
  const netatmoAuthUrl = `https://api.netatmo.com/oauth2/authorize?client_id=${clientIdNetatmo}&redirect_uri=${redirectUri}&scope=read_station&state=${netatmoState}`;

  const user_id = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE)).user.user_id

  const [selectedTierUserAccount, setSelectedTierUserAccount] = useState(null)
  const [showModalLogoutTierAccount, setShowModalLogoutTierAccount] = useState(false)
  
  useEffect(() => {
    dispatch(getProfile({ user_id }));
    dispatch(getTiersUserStatus({ user_id, data: { api: "netatmo" } }));
    dispatch(getTiersUserStatus({ user_id, data: { api: "smartthings" } }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user_id]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const authCode = urlParams.get('code');
    const state = urlParams.get('state');
    
    if (authCode) {
      let api;
      if (state === 'netatmo') {
        api = 'netatmo'
      } else {
        api = 'smartthings'
      }

      dispatch(connectTiersUser({ user_id, data: { authCode, api } }));
      urlParams.delete('code');
      urlParams.delete('state');
      navigate(`?${urlParams.toString()}`, { replace: true });
    } else {
      dispatch(getTiersUserStatus({ user_id, data: { api: "netatmo" }  }));
      dispatch(getTiersUserStatus({ user_id, data: { api: "smartthings" } }));
    }
  }, [dispatch, user_id, navigate, IsNetatmoUserConnected, IsSmartthingsUserConnected]);


  const handleNetatmoConnectRedirect = () => {
    window.location.href = netatmoAuthUrl;
  };

  // Gestion de la connexion/déconnexion pour SmartThings
  const handleLinkSmartthings = () => {
    if (IsSmartthingsUserConnected) {
      setSelectedTierUserAccount('smartthings')
      setShowModalLogoutTierAccount(true)
    } else {
      window.location.href = smartThingsAuthUrl;
    }
  }

  const handleClose = () => {
    setShowModalLogoutTierAccount(false)
  }

  // Gestion de la connexion/déconnexion pour Netatmo (si nécessaire)
  const handleLinkNetatmo = () => {
    if (IsNetatmoUserConnected) {
      setSelectedTierUserAccount('netatmo')
      setShowModalLogoutTierAccount(true)
    } else {
      window.location.href = netatmoAuthUrl;
    }
  }


  return (
    <div className={s.root}>
      <div className={s.content}>
        <div className={s.account}>
          <div className={s.item}>
            <div className={s.label}>Smartthing :</div>
            <div className={s.status}>{IsSmartthingsUserConnected ? "Connecté" : "Non connecté"}</div>
          </div>
          <div className={s.buttonsSmarthings}>
            <Button className={s.btn} onClick={handleLinkSmartthings} disabled={false}>{IsSmartthingsUserConnected ? "Se déconnecter" : "Se connecter"}</Button>
          </div>
        </div>
          
        <div className={s.account}>
          <div className={s.item}>
            <div className={s.label}>Netatmo :</div>
            <div className={s.status}>{IsNetatmoUserConnected ? "Connecté" : "Non connecté"}</div>
          </div>
          <div className={s.buttonsNetatmo}>
            <Button className={s.btn} onClick={handleLinkNetatmo} disabled={false}>{IsNetatmoUserConnected ? "Se déconnecter" : "Se connecter"}</Button>
          </div>

        </div>
      </div>

      <ModalLogoutTierAccount showModalLogoutTierAccount={showModalLogoutTierAccount} selectedTierUserAccount={selectedTierUserAccount} handleClose={handleClose}/>
    </div>
  )
}

export default CardTiersAccount
