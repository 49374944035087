import { defaultAxiosInstance } from '../../axiosConfig';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getConsumptionTrend = createAsyncThunk(
  'indicators/consumption_trend',
  async ({ parameters }, { rejectWithValue }) => {
    try {
      const response = await defaultAxiosInstance.get(`/indicators/consumption_trend`, { params: parameters });
      const data = response.data;
      return { indicatorType: 'consumption_trend', period: parameters.period, data: data,  }
    } catch (error) {
      return rejectWithValue(error.message || 'An error occurred');
    }
  }
);

export const getOptimalContract = createAsyncThunk(
  'indicators/optimal_contract',
  async ({ parameters }, { rejectWithValue }) => {
    try {
      const {} = parameters;
      const params = {};
      const response = await defaultAxiosInstance.get(`/indicators/optimal_contract`, { params });
      const data = response.data;
      return { type: 'optimal_contract', data }
    } catch (error) {
      return rejectWithValue(error.message || 'An error occurred');
    }
  }
);

export const getPeakOffpeakRatio = createAsyncThunk(
  'indicators/peak_offpeak_ratio',
  async ({ parameters }, { rejectWithValue }) => {
    try {
      const response = await defaultAxiosInstance.get(`/indicators/peak_offpeak_ratio`, { params: parameters });
      const data = response.data;
      return { indicatorType: 'peak_offpeak_ratio', period: parameters.period, data: data }
    } catch (error) {
      return rejectWithValue(error.message || 'An error occurred');
    }
  }
);

export const getHighConsumptionAlert = createAsyncThunk(
  'indicators/high_consumption_alert',
  async ({ parameters }, { rejectWithValue }) => {
    try {
      const response = await defaultAxiosInstance.get(`/indicators/high_consumption_alert`, { params: parameters });
      const data = response.data;
      return { indicatorType: 'high_consumption_alert', period: parameters.period, data: data }
    } catch (error) {
      return rejectWithValue(error.message || 'An error occurred');
    }
  }
);

export const getDayNightDiff = createAsyncThunk(
  'indicators/day_night_diff',
  async ({ parameters }, { rejectWithValue }) => {
    try {
      const {} = parameters;
      const params = {};
      const response = await defaultAxiosInstance.get(`/indicators/day_night_diff`, { params });
      const data = response.data;
      return { type: 'day_night_diff', data }
    } catch (error) {
      return rejectWithValue(error.message || 'An error occurred');
    }
  }
);

export const getCO2Mix = createAsyncThunk(
  'indicators/co2_mix',
  async ({ parameters }, { rejectWithValue }) => {
    try {
      const {} = parameters;
      const params = {};
      const response = await defaultAxiosInstance.get(`/indicators/co2_mix`, { params });
      const data = response.data;
      return { type: 'co2_mix', data }
    } catch (error) {
      return rejectWithValue(error.message || 'An error occurred');
    }
  }
);

export const getPerformanceIndex = createAsyncThunk(
  'indicators/performance_index',
  async ({ parameters }, { rejectWithValue }) => {
    try {
      const {} = parameters;
      const params = {};
      const response = await defaultAxiosInstance.get(`/indicators/performance_index`, { params });
      const data = response.data;
      return { type: 'performance_index', data }
    } catch (error) {
      return rejectWithValue(error.message || 'An error occurred');
    }
  }
);

export const getBuildingRanking = createAsyncThunk(
  'indicators/building_ranking',
  async ({ parameters }, { rejectWithValue }) => {
    try {
      const response = await defaultAxiosInstance.get(`/indicators/building_ranking`, { params: parameters });
      const data = response.data;
      return { indicatorType: 'building_ranking', period: parameters.period, data: data,  }
    } catch (error) {
      return rejectWithValue(error.message || 'An error occurred');
    }
  }
);

export const getTypicalDay = createAsyncThunk(
  'indicators/typical_day',
  async ({ parameters }, { rejectWithValue }) => {
    try {
      const {} = parameters;
      const params = {};
      const response = await defaultAxiosInstance.get(`/indicators/typical_day`, { params });
      const data = response.data;
      return { type: 'typical_day', data }
    } catch (error) {
      return rejectWithValue(error.message || 'An error occurred');
    }
  }
);

export const getIndoorTempTrend = createAsyncThunk(
  'indicators/indoor_temp_trend',
  async ({ parameters }, { rejectWithValue }) => {
    try {
      const {} = parameters;
      const params = {};
      const response = await defaultAxiosInstance.get(`/indicators/indoor_temp_trend`, { params });
      const data = response.data;
      return { type: 'indoor_temp_trend', data }
    } catch (error) {
      return rejectWithValue(error.message || 'An error occurred');
    }
  }
);

export const getHumidityRate = createAsyncThunk(
  'indicators/humidity_rate',
  async ({ parameters }, { rejectWithValue }) => {
    try {
      const {} = parameters;
      const params = {};
      const response = await defaultAxiosInstance.get(`/indicators/humidity_rate`, { params });
      const data = response.data;
      return { type: 'humidity_rate', data }
    } catch (error) {
      return rejectWithValue(error.message || 'An error occurred');
    }
  }
);

export const getComfortRange = createAsyncThunk(
  'indicators/comfort_range',
  async ({ parameters }, { rejectWithValue }) => {
    try {
      const {} = parameters;
      const params = {};
      const response = await defaultAxiosInstance.get(`/indicators/comfort_range`, { params });
      const data = response.data;
      return { type: 'comfort_range', data }
    } catch (error) {
      return rejectWithValue(error.message || 'An error occurred');
    }
  }
);

export const getHoursOutsideComfort = createAsyncThunk(
  'indicators/hours_outside_comfort',
  async ({ parameters }, { rejectWithValue }) => {
    try {
      const {} = parameters;
      const params = {};
      const response = await defaultAxiosInstance.get(`/indicators/hours_outside_comfort`, { params });
      const data = response.data;
      return { type: 'hours_outside_comfort', data }
    } catch (error) {
      return rejectWithValue(error.message || 'An error occurred');
    }
  }
);

export const getCO2Level = createAsyncThunk(
  'indicators/co2_level',
  async ({ parameters }, { rejectWithValue }) => {
    try {
      const {} = parameters;
      const params = {};
      const response = await defaultAxiosInstance.get(`/indicators/co2_level`, { params });
      const data = response.data;
      return { type: 'co2_level', data }
    } catch (error) {
      return rejectWithValue(error.message || 'An error occurred');
    }
  }
);

export const getGoodAirQualityTime = createAsyncThunk(
  'indicators/good_air_quality_time',
  async ({ parameters }, { rejectWithValue }) => {
    try {
      const {} = parameters;
      const params = {};
      const response = await defaultAxiosInstance.get(`/indicators/good_air_quality_time`, { params });
      const data = response.data;
      return { type: 'good_air_quality_time', data }
    } catch (error) {
      return rejectWithValue(error.message || 'An error occurred');
    }
  }
);

