import React from 'react';
import s from './Billing.module.scss';
import CardSubscription from '../CardSubscription/CardSubscription'
function Billing() {
  return (
    <div className={s.root}>
      <div className={s.subscription}>
        <div className={s.title}>
          Abonnement standard
        </div>
        <CardSubscription />
      </div>
    </div>
  )
}

export default Billing;
