import React, { useEffect } from 'react'
import { useDispatch } from "react-redux"
import s from './Account.module.scss'
import { getProfile } from '../../features/user/userActions'
import { useMatch } from 'react-router-dom'
import HeaderAccount from '../../components/Headers/HeaderAccount/HeaderAccount'
import Profile from './components/Profile/Profile'
import Company from './components/Company/Company'
import TiersAccount from './components/TiersAccount/TiersAccount'
import Billing from './components/Billing/Billing'

function Account() {
  
  const dispatch = useDispatch()

  const isMatchMyProfile = useMatch('/account/my_account');
  const isMatchCompany = useMatch('/account/company');
  const isMatchAssociatedAccount  = useMatch('/account/associated_account');
  const isMatchBilling = useMatch('/account/billing');

  const user_id = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE)).user.user_id

  useEffect(() => {
    dispatch(getProfile({ user_id }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user_id])

  return (
    <div className={s.root}>
      <HeaderAccount/>
      <div className={s.content}>
        {isMatchMyProfile && <Profile/>}
        {isMatchCompany && <Company/>}
        {isMatchAssociatedAccount && <TiersAccount/>}   
        {isMatchBilling && <Billing/>}         
      </div>
    </div>
  )
}

export default Account
