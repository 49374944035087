import React from 'react';
import s from './ModalLogoutTierAccount.module.scss';
import Modal from '../../../../components/Modal/Modal';
import { useDispatch } from 'react-redux';
import { disconnectTiersUser } from '../../../../features/tiersUser/tiersUserAction';
import Icon from '../../../../components/Icon/Icon';
import { Button } from 'reactstrap';

function ModalLogoutTierAccount({ showModalLogoutTierAccount, selectedTierUserAccount, handleClose}) {

  const dispatch = useDispatch()

  const user_id = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE)).user.user_id

  const handleDisconnectAccount = () => {
    dispatch(disconnectTiersUser({ user_id, data: { api: selectedTierUserAccount } }));
  }

  return (
    <Modal show={showModalLogoutTierAccount}>
      <div className={s.header}>
        <div className={s.title}>DÉCONNEXION DU COMPTE TIERS</div>
        <Icon name='close' size={'1.5rem'} color={'#30465E'} fill={0} onClick={handleClose} cursor />
      </div>
      <div className={s.content}>
        <div className={s.message}>Êtes vous sûr de vouloir déconnecter votre compte {selectedTierUserAccount} ?</div>
        <Button className={s.btn} onClick={handleDisconnectAccount}>Déconnecter</Button>
      </div>
    </Modal>
  );
}

export default ModalLogoutTierAccount;