import React, { useState, useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import s from './Devices.module.scss'
import CardDevice from './components/CardDevice/CardDevice'
import { getDevices } from '../../../../features/devices/devicesActions'
import { selectDevicesByBuilding, selectDevicesLinkedByBuilding, selectDevicesUnlinked } from '../../../../selectors/selectDevices'
import ModalAddDevice from './components/ModalAddDevice/ModalAddDevice'
import ModalDeleteDevice from './components/ModalDeleteDevice/ModalDeleteDevice'
import { initActiveDevice } from '../../../../features/navigation/navigationSlice'
import { saveNavigationState } from '../../../../features/navigation/navigationActions'
import store from '../../../../redux/store';
import { selectActiveBuilding } from '../../../../selectors/selectNavigation'
import { Button } from 'reactstrap'
import Icon from '../../../../components/Icon/Icon'
import { getBuildings } from '../../../../features/buildings/buildingsActions'
import ModalUpdateDevice from './components/ModalUpdateDevice/ModalUpdateDevice'
import ModalDissociateDevice from './components/ModalDissociateDevice/ModalDissociateDevice'
import { useMatch } from 'react-router-dom'

function Devices() {

  const dispatch = useDispatch()

  const user_id = JSON.parse(localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE))?.user?.user_id;

  const building = useSelector(selectActiveBuilding, shallowEqual)
  const building_id = building?.building_id
  
  const devicesUnlinked = useSelector(selectDevicesUnlinked, shallowEqual)
  const devicesLinked = useSelector(selectDevicesLinkedByBuilding(building_id), shallowEqual)

  const devices = useSelector(selectDevicesByBuilding(building_id), shallowEqual)

  const [showModalAddDevice, setShowModalAddDevice] = useState(false)
  const [showModalUpdateDevice, setShowModalUpdateDevice] = useState(false)
  const [showModalDeleteDevice, setShowModalDeleteDevice] = useState(false)
  const [showModalDissociateDevice, setShowModalDissociateDevice] = useState(false)
  const [selectedDevice, setSelectedDevice] = useState(false)

  const handleShowModal = (modalType) => {
    switch (modalType) {
      case 'add':
        setShowModalAddDevice(true);
        break;
      case 'update':
        setShowModalUpdateDevice(true);
        break;
      case 'delete':
        setShowModalDeleteDevice(true);
        break;
      case 'dissociate':
        setShowModalDissociateDevice(true);
        break;
      default:
        break;
    }
  }

  const handleCloseModal = (modalType) => {
    switch (modalType) {
      case 'add':
        setShowModalAddDevice(false);
        break;
      case 'update':
        setShowModalUpdateDevice(false);
        break;
      case 'delete':
        setShowModalDeleteDevice(false);
        break;
      case 'dissociate':
        setShowModalDissociateDevice(false);
        break;
      default:
        break;
    }
    dispatch(initActiveDevice())
    dispatch(saveNavigationState({ user_id, state: { ...store.getState().navigation } }))
    .then(() => dispatch(getDevices({ user_id })))
    .then(() => dispatch(getBuildings({ user_id })))
  }

  return (
    <div className={s.root}>

      <div className={s.header}>
        <div className={s.title}>Mes équipements</div>
        <Button color="primary" onClick={() => handleShowModal('add')}>
          <Icon name="add" size={'1.5rem'} color={'#FFF'} fill={1}/>
          <span className={s.item}>Ajouter un équipement</span>
        </Button>
      </div>

      {devices.length === 0 && devicesLinked.length === 0 &&
      <div className={s.message}>
        <div>Aucun équipement !</div>
        <div>Veuillez ajouter un équipement</div>
      </div>}
      
      <div className={s.content}>
        
        {devices?.length > 0 && devices.map((device, index) => (
          <CardDevice key={index} device={device} handleShowModal={handleShowModal}/>
        ))}
        
        {building_id !== '' && <ModalAddDevice showModalAddDevice={showModalAddDevice} handleClose={() => handleCloseModal('add')} onFinish={() => handleCloseModal('add')}/>}
        {building_id !== '' && <ModalUpdateDevice showModalUpdateDevice={showModalUpdateDevice} handleClose={() => handleCloseModal('update')}/>}
        {building_id !== '' && <ModalDeleteDevice showModalDeleteDevice={showModalDeleteDevice} handleClose={() => handleCloseModal('delete')}/>}
        {building_id !== '' && <ModalDissociateDevice showModalDissociateDevice={showModalDissociateDevice} handleClose={() => handleCloseModal('dissociate')} />}
      </div>
    </div>
  )
}

export default Devices