import React, { useEffect, useRef, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import s from './HeaderAccount.module.scss'
import { Button } from 'reactstrap'
import { selectProfile } from '../../../selectors/selectUser'
import { userLogout } from '../../../features/auth/authSlice'
import { resetState } from '../../../features/auth/authActions'
import { useNavigate } from 'react-router-dom'
import Icon from '../../Icon/Icon'
import NavigationAccount from '../../Navigation/NavigationAccount/NavigationAccount'

function HeaderAccount() { 
  return (
    <div className={s.header}>
        <NavigationAccount />
    </div>
  )
}

export default HeaderAccount
